<template>
	<div class="sld_contract_add">
		<div class="container">
			<h3 v-if="step == 0">{{ L['合同申请'] }}</h3>
			<h3 v-else>
				{{ L['合同申请'] }}<span>{{ L['（纸质合同的申请需要由买家下载纸质合同并签署后邮寄给相应的供应商）'] }}</span>
			</h3>

			<div class="bd7 flex-col">
				<div class="box4 flex-col">
					<div class="bd8 flex-row">
						<div class="outer9 flex-col" :class="{ active: step > 0 }">
							<span class="word17">1</span>
						</div>
						<div class="outer10 flex-col"></div>
						<div class="outer11 flex-col" :class="{ active: step > 1 }">
							<span class="txt19">2</span>
						</div>
						<div class="outer10 flex-col"></div>
						<div class="outer11 flex-col" :class="{ active: step > 2 }">
							<span class="txt19">3</span>
						</div>
						<div class="outer10 flex-col"></div>
						<div class="outer11 flex-col" :class="{ active: step > 3 }">
							<span class="txt19">4</span>
						</div>
					</div>
					<div class="bd6 flex-row">
						<span class="txt20" :class="{ active: step > 0 }">{{ L['选择订单'] }}</span>
						<span class="txt20 info15" :class="{ active: step > 1 }">{{ L['确认订单'] }}</span>
						<span class="txt20 txt21" :class="{ active: step > 2 }">{{ L['信息确认'] }}</span>
						<span class="txt20 word19" :class="{ active: step > 3 }">{{ L['合同申请成功'] }}</span>
					</div>

					<template v-if="step == 1 || step == 2">
						<template v-if="step == 1">
							<div class="bd10 flex-row">
								<span class="word27">{{ L['订单号：'] }}</span>
								<div class="block7 flex-col">
									<el-input v-model="searchVal.orderSn" :placeholder="L['请输入订单号']" />
								</div>
								<span class="info16">{{ L['采购单号：'] }}</span>
								<div class="block7 flex-col">
									<el-input v-model="searchVal.purchaseSn" :placeholder="L['请输入采购单号']" />
								</div>
								<span class="info16">{{ L['完成时间：'] }}</span>
								<div class="block7 flex-col">
									<div class="outer2 flex-row">
										<el-date-picker v-model="selectTime2" type="daterange"
											:range-separator="L['至']" :start-placeholder="L['开始日期']"
											:end-placeholder="L['结束日期']" value-format="YYYY-MM-DD">
										</el-date-picker>
									</div>
								</div>
							</div>
							<div class="bd10 flex-row">
								<span class="word27">{{ L['下单时间：'] }}</span>
								<div class="block7 flex-col">
									<div class="outer2 flex-row">
										<el-date-picker v-model="selectTime" type="daterange"
											:range-separator="L['至']" :start-placeholder="L['开始日期']"
											:end-placeholder="L['结束日期']" value-format="YYYY-MM-DD">
										</el-date-picker>
									</div>
								</div>
								<span class="word27">{{ L['供应商：'] }}</span>
								<div class="block7 flex-col">
									<el-input v-model="searchVal.storeName" :placeholder="L['请输入供应商']" />
								</div>
								<div class="bd14 flex-row">
									<button class="layer7 flex-col" @click="goSearch">
										<span class="info19">{{ L['搜索'] }}</span>
									</button>
									<div class="layer8 flex-col" @click="initSearch(false)">
										<span class="txt17">{{ L['重置'] }}</span>
									</div>
								</div>
							</div>
						</template>
						<template v-else>
							<span class="txt111">{{ L['请确认需申请合同的订单'] }}</span>
							<span class="txt111 txt112">{{L['已选择以下']}}{{ applyList.data.length }}{{L['个订单']}}</span>
						</template>
						<div>
							<div class="bd17_box"
								:style="(contractList.data.length || applyList.data.length) ? 'overflow-x:auto;' : ''">
								<div class="bd17 flex-col">
									<div class="layer31 flex-row">
										<el-checkbox v-if="step == 1 && contractList.data.length > 0"
											class="layer31_check" v-model="allCheckVal" @change="allCheck">{{L['全选']}}
										</el-checkbox>
										<span class="layer31_check" v-else></span>
										<span class="txt27 word31">{{ L['订单号'] }}</span>
										<span class="txt27 word32">{{ L['采购单号'] }}</span>
										<span class="txt27 word35">{{ L['订单金额'] }}</span>
										<span class="txt27 word33">{{ L['下单时间'] }}</span>
										<span class="txt27 txt28">{{ L['供应商'] }}</span>
									</div>
								</div>
								<template v-if="step == 1">
									<template v-if="contractList.data.length > 0">
										<div class="bd18 flex-col" v-for="(item) in contractList.data"
											:key="item.orderSn">
											<div class="mod3 flex-row">
												<template v-if="step == 1">
													<div class="box5 flex-col">
														<el-checkbox v-model="item.isCheck"
															@change="singleCheck">
														</el-checkbox>
													</div>
												</template>
												<span class="info20 word36">{{ item.orderSn }}</span>
												<span class="info20 word37">{{
													item.purchaseSn ?
													item.purchaseSn : '--'
												}}</span>
												<span class="info20 txt33">￥{{
													item.orderAmount.toFixed(2)
												}}</span>
												<span class="info20 info21">{{ item.createTime }}</span>
												<span class="info20 txt32">{{ item.storeName }}</span>

											</div>
										</div>
									</template>
									<template v-else>
										<SldCommonEmpty totalWidth="967" :tip="L['暂无待申请合同']" />
									</template>
								</template>
								<template v-else-if="step == 2">
									<template v-if="applyList.data.length > 0">
										<div class="bd18 flex-col" v-for="item in applyList.data"
											:key="item.orderSn">
											<div class="mod3 flex-row">
												<span class="info20 word36">{{ item.orderSn }}</span>
												<span class="info20 word37">{{
													item.purchaseSn ?
													item.purchaseSn : '--'
												}}</span>
												<span class="info20 txt33">￥{{
													item.orderAmount.toFixed(2)
												}}</span>
												<span class="info20 info21">{{ item.createTime }}</span>
												<span class="info20 txt32">{{ item.storeName }}</span>
											</div>
										</div>
									</template>
								</template>

							</div>


						</div>

					</template>
					<template v-else-if="step == 3">
						<div class="txt14">{{ L['请确认并填写以下信息'] }}</div>
						<div class="mod7 flex-row">
							<div class="info17">{{ L['需方信息：'] }}</div>
							<div class="info18" style="text-align:left;">{{
								authentication.userName ||
								memberInfo.memberTrueName
							}}<span v-if="authentication.userType == 1 || authentication.userType == 2"
									class="tag">{{ authentication.userType == 1 ? L['个人'] : L['企业'] }}</span>
							</div>
						</div>
						<div class="mod7 flex-row">
							<div class="info17">{{ L['自定义合同号：'] }}</div>
							<input v-model="contractApply.customContractNo" :placeholder="L['请输入自定义合同号']"
								maxlength="30" />
						</div>
						<div class="mod7 flex-row">
							<div class="info17">{{ L['合同备注：'] }}</div>
							<textarea v-model="contractApply.contractRemark" :placeholder="L['请输入合同备注']"
								maxlength="50"></textarea>
						</div>
						<div class="mod7 flex-row">
							<div class="info17">{{ L['单位名称：'] }}</div>
							<input v-model="contractApply.companyName" :placeholder="L['请输入单位名称']" maxlength="30" />
						</div>
						<div class="mod7 flex-row">
							<div class="info17"><span class="">{{ L['单位地址：'] }}</span></div>
							<textarea v-model="contractApply.companyAddress" :placeholder="L['请输入单位地址']"
								maxlength="50"></textarea>
						</div>
						<div class="mod7 flex-row">
							<div class="info17">{{ L['法定代理人：'] }}</div>
							<input v-model="contractApply.legalAgent" :placeholder="L['请输入法定代理人']" maxlength="20" />
						</div>
						<div class="mod7 flex-row">
							<div class="info17">{{ L['委托代理人：'] }}</div>
							<input v-model="contractApply.agent" :placeholder="L['请输入委托代理人']" maxlength="20" />
						</div>
						<div class="mod7 flex-row">
							<div class="info17">{{ L['电话：'] }}</div>
							<input v-model="contractApply.telephone" :placeholder="L['请输入电话']" maxlength="11" />
						</div>
						<div class="mod7 flex-row">
							<div class="info17"><span class="">{{ L['单位邮编：'] }}</span></div>
							<input v-model="contractApply.zipCode" :placeholder="L['请输入单位邮编']" maxlength="30" />
						</div>
						<div class="mod7 flex-row">
							<div class="info17"><span class="">{{ L['单位传真：'] }}</span></div>
							<input v-model="contractApply.fax" :placeholder="L['请输入单位传真']" maxlength="20" />
						</div>
						<div class="mod7 flex-row">
							<div class="info17"><span class="">{{ L['开户银行：'] }}</span></div>
							<input v-model="contractApply.bankName" :placeholder="L['请输入开户银行名称']" maxlength="30" />
						</div>
						<div class="mod7 flex-row">
							<div class="info17"><span class="">{{ L['银行账号：'] }}</span></div>
							<input v-model="contractApply.bankAccount" :placeholder="L['请输入开户银行账号']" maxlength="19" />
						</div>
						<div class="mod7 flex-row">
							<div class="info17"><span class="">{{ L['税号：'] }}</span></div>
							<input v-model="contractApply.taxNo" :placeholder="L['请输入税号']" maxlength="20" />
						</div>
						<div class="mod7 flex-row">
							<div class="info17"><span class="">{{ L['身份证号：'] }}</span></div>
							<input v-model="contractApply.idCard" :placeholder="L['请输入开户身份证号']" maxlength="18" />
						</div>
						<div class="mod7 flex-row">
							<div class="info17"><span class="">{{ L['开票方式：'] }}</span></div>
							<el-radio v-model="contractApply.billingMethod" label="1">{{ L['增值税专用发票'] }}</el-radio>
							<el-radio v-model="contractApply.billingMethod" label="2">{{ L['增值税普通发票'] }}</el-radio>
						</div>
						<div class="mod8 flex-col">
							<span class="word28"></span>
						</div>
					</template>
					<template v-else>
						<img class="pic1" referrerpolicy="no-referrer"
							src="@/assets/prototype/sketch_complete.png" />
						<span class="info10">{{ L['您的合同（草稿）创建成功'] }}</span>
						<span class="info11">
							{{ L['当前创建的为合同草稿，可下载预览；转为正式合同后具有法律效力，落款章有效。'] }}
						</span>
						<div class="outer5 flex-col">
							<div class="section1 flex-col">
								<div class="bd30 flex-row">
									<span class="word36">{{ L['合同号'] }}</span>
									<span class="word36 word37">{{ L['供应商'] }}</span>
									<span class="word36 word38">{{ L['操作'] }}</span>
								</div>
								<div class="bd31 flex-col"></div>
								<template v-for="item in successList.data" :key="item.contractId">
									<div class="bd32 flex-row">
										<span class="txt13">{{ item.contractCode }}</span>
										<span class="txt13 word39">{{ item.storeName }}</span>
										<span class="txt13 word40" @click="review(item.contractUrl)">{{L['预览']}}</span>
										<span class="txt13 word41" @click="setFormal(item.contractId)"
											v-if="item.state == 1">{{ L['转为正式'] }}</span>
									</div>
								</template>
							</div>
						</div>
					</template>

					<el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
						:currentPage="pageData.current" :page-size="pageData.pageSize" :page-sizes="[30, 50, 100]"
						layout="sizes, prev, pager, next, jumper" :total="pageData.total"
						:hide-on-single-page="false" class="flex_row_end_center"
						v-if="step == 1 && contractList.data.length > 0"></el-pagination>

					<button v-if="step == 1" :class="{ active: applyList.data.length > 0 }" class="bd15 flex-col"
						@click="nextStep">
						<span class="word29">{{ L['申请合同'] }}</span>
					</button>
					<div v-else-if="step == 2 || step == 3" class="mod9 flex-row">
						<div class="box2 flex-col" @click="backStep">
							<span class="word29">{{ L['上一步'] }}</span>
						</div>
						<div class="box3 flex-col" @click="nextStep">
							<span class="txt15">{{ L['下一步'] }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 编辑申请数量 start -->
		<el-dialog ref="editApply" :title="L['申请数量']" v-model="editDialog" width="440px" top="30vh">
			<div class="edit_main">
				<span>{{ L['申请数量：'] }}</span>
				<el-input type="number" v-model="applyNum" :placeholder="L['请输入申请数量']" maxlength="9" @input="checkVal" />
			</div>
			<div class="edit_btn">
				<div class="edit_cancle" @click="close_edit">{{ L['取消'] }}</div>
				<div class="edit_submit" @click="submit_edit">{{ L['确定'] }}</div>
			</div>
		</el-dialog>
		<!-- 编辑申请数量 end -->

		<el-dialog v-model="contractDialog" :title="L['转为正式合同']" width="440px" top="30vh">
			<div>
				<div class="dailog_radio">
					<!-- el-contract-start -->
					<el-radio v-model="contractState" label="1">{{ L['转为正式电子合同'] }}</el-radio>
					<!-- el-contract-end -->
					<el-radio v-model="contractState" label="2">{{ L['转为正式纸质合同'] }}</el-radio>
				</div>
				<div class="dailog_btn">
					<div class="dailog_cancle" @click="cancleDailog">{{ L['取消'] }}</div>
					<div class="dailog_submit" @click="submitDailog">{{ L['确定'] }}</div>
				</div>
			</div>
		</el-dialog>

	</div>
</template>

<script>
import { reactive, ref, getCurrentInstance, onMounted, watch } from "vue";
import MemberTitle from "@/components/MemberTitle";
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { ElMessage } from 'element-plus';
import SldCommonEmpty from '@/components/SldCommonEmpty';
export default {
	name: "FinaceContractAdd",
	components: {
		MemberTitle,
		SldCommonEmpty
	},
	setup() {
		const { proxy } = getCurrentInstance();
		const store = useStore();
		const memberInfo = store.state.memberInfo;
		const router = useRouter();
		const L = proxy.$getCurLanguage();
		const searchVal = reactive({ data: [] });
		const selectTime = ref('');
		const selectTime2 = ref('');
		const contractList = reactive({ data: [] });
		const step = ref(1);
		const applyList = reactive({ data: [], ids: '/' });  //合同申请数据
		const editDialog = ref(false); //编辑申请数量弹窗
		const editIndex = ref('');
		const applyNum = ref(0);
		const allCheckVal = ref(false);
		const successList = reactive({ data: [] });
		const contractDialog = ref(false);
		const contractId = ref(''); //编辑的合同id
		const contractState = ref('1');
		const authentication = ref({}); //认证信息
		const submitting = ref(false); //是否在提交申请合同信息
		const contractApply = reactive({
			customContractNo: "", //自定义合同号
			contractRemark: "", //合同备注
			companyName: "", //	单位名称
			companyAddress: "", //单位地址
			legalAgent: "", //法定代理人
			agent: "", //委托代理人
			telephone: "", //电话
			zipCode: "", //单位邮编
			fax: "", //单位传真
			bankName: "", //开户银行
			bankAccount: "", //银行账号
			taxNo: "", //税号
			idCard: "", //	身份证号
			billingMethod: '1', //开票方式：1-增值税专用发票；2-增值税普通发票
		});

		const pageData = reactive({
			current: 1,
			pageSize: 30,
			total: 0
		});

		const goSearch = () => {
			searchVal.startTime = selectTime.value ? selectTime.value[0] : '';
			searchVal.endTime = selectTime.value ? selectTime.value[1] : '';
			searchVal.startTime2 = selectTime2.value ? selectTime2.value[0] : '';
			searchVal.endTime2 = selectTime2.value ? selectTime2.value[1] : '';
			getContractList();
		};

		const initSearch = (notSearch) => {
			searchVal.goodsName = "";
			searchVal.goodsSpec = "";
			searchVal.orderSn = "";
			searchVal.purchaseSn = "";
			searchVal.storeName = "";
			searchVal.startTime = "";
			searchVal.endTime = "";
			searchVal.startTime2 = "";
			searchVal.endTime2 = "";
			selectTime.value = "";
			selectTime2.value = "";
			!notSearch && getContractList();
		};

		const getContractList = () => { //待申请合同列表
			let param = {
				current: pageData.current,
				pageSize: pageData.pageSize
			}
			if (searchVal.startTime) {
				param.creatTimeStartTime = searchVal.startTime + ' 00:00:00' //下单开始时间
			}
			if (searchVal.endTime) {
				param.creatTimeEndTime = searchVal.endTime + ' 23:59:59' //下单结束时间
			}
			if (searchVal.startTime2) {
				param.finishTimeStartTime = searchVal.startTime2 + ' 00:00:00' //完成开始时间
			}
			if (searchVal.endTime2) {
				param.finishTimeEndTime = searchVal.endTime2 + ' 23:59:59' //完成结束时间
			}
			if (searchVal.goodsName) { //订单名称
				param.goodsName = searchVal.goodsName
			}
			if (searchVal.goodsSpec) { //规格型号
				param.goodsSpec = searchVal.goodsSpec
			}
			if (searchVal.orderSn) { //订单号
				param.orderSn = searchVal.orderSn
			}
			if (searchVal.purchaseSn) { //采购单号
				param.purchaseSn = searchVal.purchaseSn
			}
			if (searchVal.storeName) { //供应商
				param.storeName = searchVal.storeName
			}

			proxy.$get("v3/business/front/contract/toContractList", param)
				.then((res) => {
					if (res.state == 200) {
						if (res.data.list.length > 0) {
							let totalNum = 0;
							res.data.list.forEach(item => {
								if (applyList.ids && applyList.ids.indexOf('/' + item.orderSn + '/') != -1) {
									item.isCheck = true;
									totalNum++;
								} else {
									item.isCheck = false;
								}
								item.isEdit = false;
								item.applyNum = item.buyNum - item.contractNum; //申请数量
								item.applyNumEdit = item.buyNum - item.contractNum;
								item.applyTotal = (Number(item.buyNum) * Number(item.goodsPrice)).toFixed(2); //申请金额
							})
							if (totalNum == res.data.list.length) {
								allCheckVal.value = true;
							}
						}
						contractList.data = res.data.list;
						pageData.total = res.data.pagination.total;
					} else {
						ElMessage(res.msg);
					}
				})
		};

		const allCheck = () => { //全选事件
			changeCheck('all');
		};
		const singleCheck = () => {
			changeCheck();
		}
		const changeCheck = (type) => { //切换选择状态
			let num = 0;
			let idsArr = applyList.ids.split('/');
			let arr = applyList.data;
			let ids = applyList.ids;
			contractList.data.forEach(item => {
				let index = idsArr.indexOf(item.orderSn.toString());
				if (type) {
					item.isCheck = allCheckVal.value
					if (allCheckVal.value) {
						if (index == -1) {
							arr.push(item);
							ids += item.orderSn + '/'
						}
					} else {
						if (index > 0) {
							arr.splice(index - 1, 1);
							idsArr.splice(index - 1, 1);
						}
						ids = ids.replace('/' + item.orderSn + '/', '/');
					}
				} else if (item.isCheck) {
					num++;
					if (index == -1) {
						arr.push(item);
						ids += item.orderSn + '/'
					}
				} else {
					if (index > 0) {
						arr.splice(index - 1, 1);
						idsArr.splice(index - 1, 1);
					}
					ids = ids.replace('/' + item.orderSn + '/', '/');
				}
			})
			if (!type) {
				if (allCheckVal.value && num < contractList.data.length) {
					allCheckVal.value = false;
				} else if (!allCheckVal.value && num == contractList.data.length) {
					allCheckVal.value = true;
				}
			}
			applyList.data = arr;
			applyList.ids = ids;
		};

		const checkNum = (index) => {
			let reduce = contractList.data[index].applyNumEdit % 1;
			if (contractList.data[index].applyNumEdit <= 0) {
				contractList.data[index].applyNumEdit = 1;
			} else if (contractList.data[index].applyNumEdit > (contractList.data[index].buyNum - contractList.data[index].contractNum)) {
				contractList.data[index].applyNumEdit = (contractList.data[index].buyNum - contractList.data[index].contractNum);
			} else if (reduce > 0) {
				contractList.data[index].applyNumEdit -= reduce;
			}
		};
		const setNum = (index) => {
			contractList.data[index].applyNum = contractList.data[index].applyNumEdit;
			contractList.data[index].applyTotal =
				(Number(contractList.data[index].applyNum) * Number(contractList.data[index].goodsPrice)).toFixed(2);
			contractList.data[index].isEdit = false;

			let idsArr = applyList.ids.split('/');
			let updateIndex = idsArr.indexOf(contractList.data[index].orderSn.toString());
			if (updateIndex != -1) {
				applyList.data[updateIndex - 1] = contractList.data[index];
			}
		};
		const editNum = (index) => { //编辑申请数量
			contractList.data[index].isEdit = true;
			contractList.data[index].applyNumEdit = contractList.data[index].applyNum;


		};
		const close_edit = () => {
			editDialog.value = false;
			editIndex.value = '';
			applyNum.value = '';
		};

		const submit_edit = () => {
			if (!applyNum.value) {
				ElMessage.warning(L['请输入申请数量']);
				return;
			} else if (applyNum.value < 1) {
				applyNum.value = '';
				ElMessage.warning(L['请输入正确的数量']);
				return;
			} else if (applyNum.value > (contractList.data[editIndex.value].buyNum - contractList.data[editIndex.value].contractNum)) {
				ElMessage.warning(L['申请数量最多为'] + (contractList.data[editIndex.value].buyNum - contractList.data[editIndex.value].contractNum))
				applyNum.value = contractList.data[editIndex.value].buyNum
				return;
			}
			contractList.data[editIndex.value].applyNum = applyNum.value;
			contractList.data[editIndex.value].contractNum = Number(applyNum.value);
			contractList.data[editIndex.value].applyTotal =
				(Number(applyNum.value) * Number(contractList.data[editIndex.value].goodsPrice)).toFixed(2);
			editIndex.value = '';
			editDialog.value = false;
			applyNum.value = '';
		};

		const nextStep = () => {
			if (step.value == 1) {
				if (applyList.data.length == 0) return;
				let flag = false;
				applyList.data.forEach(item => {
					if (item.applyNum <= 0) {
						flag = true;  //存在未设置的申请数量
					}
				})
				if (flag) {
					ElMessage.warning(L['请设置选中订单的申请数量'])
					return;
				}
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				})
				contractList.data.forEach(item => {
					if (item.isEdit) {
						item.isEdit = false;
					}
				})
				step.value = 2;
			} else if (step.value == 2) {
				// 确认信息
				step.value = 3;
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				})
			} else {


				if (submitting.value) { return; }
				let checkPhone = /^1[3-9]\d{9}$/g;
				let checkZipCode = /^\d{6}$/g;
				let checkFax = /^((\+|\-)?[0-9]+)+$/g;
				let checkBank = /^\d{19}$/g;
				let checkTaxNo = /^([0-9A-Z]{15}|[0-9A-Z]{18}|[0-9A-Z]{20})$/g;
				let idCard18 = /^[1-9][0-9]{5}(18|19|20)[0-9]{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)[0-9]{3}([0-9]|(X|x))/
				let idCard15 = /^[1-9][0-9]{5}[0-9]{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)[0-9]{2}[0-9]/

				if (contractApply.telephone && !checkPhone.test(contractApply.telephone)) {
					ElMessage.warning(L['请输入正确的电话']);
					return;
				} else if (contractApply.zipCode && !checkZipCode.test(contractApply.zipCode)) {
					ElMessage.warning(L['请输入正确的单位邮编']);
					return;
				} else if (contractApply.fax && !checkFax.test(contractApply.fax)) {
					ElMessage.warning(L['请输入正确的单位传真']);
					return;
				} else if (contractApply.bankAccount && !checkBank.test(contractApply.bankAccount)) {
					ElMessage.warning(L['请输入正确的银行账号']);
					return;
				} else if (contractApply.taxNo && !checkTaxNo.test(contractApply.taxNo)) {
					ElMessage.warning(L['请输入正确的税号']);
					return;
				} else if (contractApply.idCard && !(idCard18.test(contractApply.idCard) || idCard15.test(contractApply.idCard))) {
					ElMessage.warning(L['请输入正确的身份证号']);
					return;
				}
				let param = applyList.data.map(i => i.orderSn);

				contractApply.orderSns = param.join(',');
				//申请提交
				let config = {
					header: 'Content-type:application/json'
				}
				submitting.value = true;
				proxy
					.$post('v3/business/front/contract/add', contractApply, config)
					.then(res => {
						if (res.state == 200) {
							step.value = 4;
							successList.data = res.data;
							submitting.value = false;
							window.scrollTo({
								top: 0,
								behavior: 'smooth'
							})
						} else {
							ElMessage.error(res.msg);
							setTimeout(() => {
								step.value = 2;
								submitting.value = false;
							}, 500)
						}
					})
			}
		};

		const review = (url) => { //预览
			if (url) {
				window.open(url);
			} else {
				ElMessage.info(L['请在合同列表页查看']);
			}

		};

		const setFormal = (id) => { //转为正式
			contractDialog.value = true;
			contractId.value = id;
		}

		const cancleDailog = () => {
			contractDialog.value = false;
			contractId.value = '';
		};

		const submitDailog = () => {
			if (!contractState.value) {
				ElMessage.warning(L['请选择合同类型']);
			} else if (!submitting.value) {
				submitting.value = true;
				let param = {
					contractId: contractId.value,
					type: contractState.value
				}
				proxy
					.$post('v3/business/front/contract/changeState', param)
					.then(res => {
						if (res.state == 200) {
							ElMessage.success(res.msg);
							contractDialog.value = false;
							contractId.value = '';
							submitting.value = false;
							setTimeout(() => {
								router.back();
							}, 1000)
						} else {
							ElMessage.error(res.msg);
							submitting.value = false;
						}
					})
			}
		};

		const backStep = () => {
			if (step.value == 3) {
				step.value = 2;
			} else {
				initSearch(true)
				step.value = 1;
			}
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			})
		};

		//页数改变
		const handleCurrentChange = e => {
			pageData.current = Math.floor(e);
			allCheckVal.value = false;
			getContractList();
		};
		const handleSizeChange = pageSize => {
			pageData.current = 1;
			pageData.pageSize = pageSize;
			allCheckVal.value = false;
			getContractList();
		};

		const getAuth = () => { //获取认证信息
			proxy.$get("v3/business/front/contract/certificate/detail").then((res) => {
				if (res.state == 200) {
					authentication.value = res.data;
				} else {
					ElMessage.error(L['获取认证信息失败']);
				}
			})
		};

		const getOtherInfo = () => { //获取补充信息
			proxy.$get("v3/member/front/member/contractInfo/detail").then((res) => {
				if (res.state == 200) {
					Object.keys(contractApply).forEach(key => {
						if (res.data.hasOwnProperty(key) && res.data[key]) {
							contractApply[key] = res.data[key];
						}
					})
				}
			})
		};

		onMounted(() => {
			initSearch();
			getAuth();
			getOtherInfo();
		});

		return {
			L,
			memberInfo,
			searchVal,
			selectTime,
			selectTime2,
			contractList,
			step,
			applyList,
			goSearch,
			initSearch,
			nextStep,
			backStep,
			getContractList,
			editDialog,
			editIndex,
			applyNum,
			allCheckVal,
			checkNum,
			setNum,
			editNum,
			close_edit,
			submit_edit,
			allCheck,
			singleCheck,
			changeCheck,
			successList,
			review,
			setFormal,
			cancleDailog,
			submitDailog,
			pageData,
			handleCurrentChange,
			handleSizeChange,
			contractDialog,
			contractState,
			contractApply,
			authentication,
			submitting,
			getOtherInfo,
		};
	},
};
</script>

<style lang="scss" scoped>
@import "@/style/contract/addContract.scss";
</style>
<style>
.bd7 .block7 .el-input__inner {
	width: 222px;
	height: 32px;
	position: absolute;
	left: 0;
	top: 0;
}

.bd7 .block7 .el-select {
	position: absolute;
	left: 0;
	top: 0;
}

.bd7 .block7 .el-input--suffix .el-input__inner {
	width: 222px;
	height: 32px;
}

.bd7 .block7 .el-select .el-input {
	width: 222px;
}

.bd7 .block7 .el-input__suffix {
	top: -3px;
}

.bd7 .block7 .el-range-editor.el-input__inner {
	width: 222px;
	height: 32px;
}

.bd7 .block7 .el-date-editor .el-range-separator {
	width: 26px;
	line-height: 24px;
}

.bd7 .block7 .el-range-editor .el-range-input {
	font-size: 12px;
}
</style>
<style lang="scss">
.sld_contract_add {
	.edit_main {
		.el-input {
			width: 260px;
		}

		.el-input__inner {
			width: 260px;
		}
	}

	.dailog_radio {
		display: flex;
		align-items: center;
		flex-direction: column;

		.el-radio {
			margin: 0 10px 20px 0;
		}
	}

	.dailog_btn {
		margin-top: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.dailog_cancle {
		width: 90px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border-radius: 2px;
		border: 1px solid $colorI;
		margin-right: 20px;
		cursor: pointer;
	}

	.dailog_submit {
		width: 90px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border-radius: 2px;
		color: #FFFFFF;
		background-color: $colorMain;
		border: 1px solid $colorMain;
		cursor: pointer;
	}

	.edit_main {
		.el-input {
			width: 260px;
		}

		.el-input__inner {
			width: 260px;
			padding-right: 0;
		}
	}

	.el-pagination {
		width: 872px;
		margin-left: 95px;
		padding-top: 15px;
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}

	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.sld_common_empty {
		height: 300px !important;
		padding-top: 68px !important;
	}
}
</style>